<!--
 * @Description: 结算单费用支出详情
 * @Author: 琢磨先生
 * @Date: 2022-11-24 10:22:03
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-11-24 11:56:51
-->

<template>
  <el-dialog
    title="费用支出详情"
    v-model="visibleDialog"
    width="800px"
    @closed="onClosed"
  >
    <div v-loading="loading">
      <el-descriptions>
        <el-descriptions-item label="费用项">{{
          model.expense_item_name
        }}</el-descriptions-item>
        <el-descriptions-item label="总额(元)"
          >￥{{ model.decimal_total_amount }}</el-descriptions-item
        >
        <el-descriptions-item label="成本(元)"
          >￥{{ model.decimal_cost_amount }}</el-descriptions-item
        >
        <el-descriptions-item label="业主(元)"
          >￥{{ model.decimal_landlord_amount }}({{
            model.decimal_expense_item_ratio
          }}%)</el-descriptions-item
        >
        <el-descriptions-item label="商户(元)"
          >￥{{ model.decimal_business_amount }}</el-descriptions-item
        >
        <el-descriptions-item label="日期">{{
          model.date
        }}</el-descriptions-item>
        <el-descriptions-item label="数量">{{
          model.number
        }}</el-descriptions-item>
        <el-descriptions-item label="单价">
          <span v-if="model.decimal_unit_price > 0"
            >{{ model.decimal_unit_price }}/{{ model.expense_item_unit }}</span
          >
        </el-descriptions-item>
      </el-descriptions>

      <el-divider>支出源数据</el-divider>
      <el-descriptions title="费用记录" v-if="model.exp_record">
        <el-descriptions-item label="费用项">{{
          model.exp_record.expense_item_name
        }}</el-descriptions-item>
        <el-descriptions-item label="总额(元)"
          >￥{{ model.exp_record.decimal_total_amount }}</el-descriptions-item
        >
        <el-descriptions-item label="成本(元)"
          >￥{{ model.exp_record.decimal_cost_amount }}</el-descriptions-item
        >
        <el-descriptions-item label="业主(元)"
          >￥{{ model.exp_record.decimal_landlord_amount }}({{
            model.exp_record.decimal_expense_item_ratio
          }}%)</el-descriptions-item
        >
        <el-descriptions-item label="商户(元)"
          >￥{{
            model.exp_record.decimal_business_amount
          }}</el-descriptions-item
        >
        <el-descriptions-item label="日期">{{
          model.exp_record.pay_date
        }}</el-descriptions-item>
        <el-descriptions-item label="数量">{{
          model.exp_record.number
        }}</el-descriptions-item>
        <el-descriptions-item label="单价">
          <span v-if="model.exp_record.decimal_unit_price > 0"
            >{{ model.exp_record.decimal_unit_price }}/{{
              model.exp_record.expense_item_unit
            }}</span
          >
        </el-descriptions-item>
        <el-descriptions-item label="备注" >{{
          model.exp_record.remark
        }}</el-descriptions-item>
        <el-descriptions-item label="创建人">{{
          model.exp_record.emp.chs_name
        }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{
          model.exp_record.create_at
        }}</el-descriptions-item>
      </el-descriptions>
      <div class="image__preview" v-if="model.exp_record">
        <el-image v-for="(url,i) in model.exp_record.pic_urls" :key="url"
          style="width: 120px; height: 90px;margin:0 10px 10px 0;"
          :src="url"
          :preview-src-list="model.exp_record.pic_urls"
          :initial-index="i"
          fit="cover"
        />
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visibleDialog: false,
      loading: false,
      model: {},
    };
  },
  props: ["id"],
  emits: ["close"],
  watch: {
    id: {
      handler() {
        if (this.id) {
          this.visibleDialog = true;
          this.loadDtl();
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 加载详情
     */
    loadDtl() {
      this.loading = true;
      this.$http
        .get("seller/v1/landlordBill/expense_dtl?id=" + this.id)
        .then((res) => {
          if (res.code == 0) {
            this.model = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onClosed() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.image__preview{
    display: flex;
    flex-wrap: wrap;

}
</style>