<!--
 * @Description: 房东计算账单详情
 * @Author: 琢磨先生
 * @Date: 2022-08-09 02:22:56
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-12 13:02:36
-->
<template>
  <el-drawer
    title="账单详情"
    v-model="visibleDrawer"
    size="1200px"
    @closed="onClosed" 
    :destroy-on-close="true"
  > 
   <dtl-page :model="model" @reload="loadData"></dtl-page>
    <template #footer>
      <span v-if="model.is_rebuild">正在重新生成中...</span>
      <el-button
        type="danger"
        v-if="!model.is_rebuild"
        @click="onRebuild"
        :loading="rebuilding"
        :disabled="saving"
      v-power="'seller/v1/landlordbill/rebuild'">重新生成</el-button>
      <el-button
        type="primary"
        :loading="saving"
        v-power="'seller/v1/landlordbill/put_review'"
        v-if="!model.is_rebuild"
        :disabled="rebuilding"
        @click="onSubmitReview"
      >提交复核</el-button>
    </template>
  </el-drawer>
  <!-- <edit-expense
    :id="edit_expense_id"
    :visible="visibleDialog"
    @close="
      this.visibleDialog = false;
      this.edit_expense_id = '';
    "
    @success="loadData"
  ></edit-expense>-->
  <exp-dtl :id="bill_exp_id" @close="bill_exp_id = ''"></exp-dtl>
</template>

<script>
// import EditExpense from "../../../house/expenditure/edit_expense.vue";
import DtlPage from '../dtl_page.vue'
import ExpDtl from "../exp_dtl.vue";
export default {
  components: {
    DtlPage,
    ExpDtl,
  },
  data() {
    return {
      loading: false,
      visibleDrawer: false,
      //提交复核中
      saving: false,
      //重建当中
      rebuilding: false,
      //显示导入弹框
      visibleDialog: false,
      //编辑费用id
      edit_expense_id: "",
      model: {},
      //查看费用支出详情的id
      bill_exp_id: "",
    };
  },
  emits: ["close", "success"],
  props: ["id"],
  watch: {
    id: {
      handler() {
        if (this.id) {
          this.visibleDrawer = true;
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    /**
     * 加载详情
     */
    loadData() {
      this.loading = true;
      this.$http
        .get("seller/v1/LandlordBill/dtl?id=" + this.id)
        .then((res) => {
          if (res.code == 0) {
            this.model = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onClosed() {
      console.log("aa");
      this.$emit("close");
    },

    /**
     * 修改费用支出
     * @param {*} item
     */
    onEditExpense(item) {
      this.edit_expense_id = item.expense_record_id;
      this.visibleDialog = true;
    },

    /**
     * 删除费用支出
     */
    onDeleteExpense(item) {
      this.$http
        .get("seller/v1/expense/record/del?id=" + item.expense_record_id)
        .then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: res.msg });
            this.loadData();
          }
        });
    },

    // /**
    //  *
    //  */
    // expenseTableRowClass(o) {
    //   if (o.row.original_is_deleted) {
    //     return "tr_delete_row";
    //   }
    //   if (o.row.original_is_new) {
    //     return "tr_new_row";
    //   }
    //   if (o.row.is_wrong) {
    //     return "tr_wrong_row";
    //   }
    // },

    /**
     * 创建结算账单
     */
    onRebuild() {
      this.$confirm("确定要重新生成账单？", "提醒").then(() => {
        this.rebuilding = true;
        this.$http
          .get("seller/v1/LandlordBill/rebuild?id=" + this.id)
          .then((res) => {
            if (res.code == 0) {
              this.model.is_rebuild = true;
              this.$message.success(res.msg);
              setTimeout(() => {
                this.loadData();
              }, 5000);
            }
          })
          .finally(() => {
            this.rebuilding = false;
          });
      });
    },

    /**
     * 创建结算账单
     */
    onSubmitReview() {
      this.$confirm("确定要提交复核？", "提醒").then(() => {
        this.rebuilding = true;
        this.$http
          .get("seller/v1/LandlordBill/put_review?id=" + this.id)
          .then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
              this.$emit("success");
              this.visibleDrawer = false;
            }
          })
          .finally(() => {
            this.rebuilding = false;
          });
      });
    },

    /**
     * 查看费用支出详情
     */
    onViewExpDtl(item) {
      console.log(item);
      this.bill_exp_id = item.id;
    },

    /**
     * 支出合并
     */
    expenseSummaries(o) {
      var array = [];
      o.columns.forEach((column, i) => {
        array[i] = "";
        if (i == 0) {
          array[i] = "合计";
        }
        if ([2, 3, 4, 5].find((o) => o == i)) {
          const values = o.data.map((item) => Number(item[column.property]));
          if (!values.every((val) => isNaN(val))) {
            var sum = values.reduce((prev, cur) => {
              return prev + cur;
            });
            array[i] = `￥${sum / 100}`;
          }
        }
      });
      return array;
    },
  },
};
</script>
<!-- 
<style   scoped>
.box {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.box .info {
  margin-top: 20px;
}

.box .data {
  flex-grow: 1;
}

.el-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.el-tabs__content {
  height: 1px;
  flex-grow: 1;
}
</style>

<style>
.bill_custom_class .el-tabs__content {
  height: 1px;
  flex-grow: 1;
}
.bill_custom_class .el-tabs__content {
  height: 1px;
  flex-grow: 1;
}
.bill_custom_class .el-tab-pane {
  height: 100%;
}
.bill_custom_class .el-table {
  height: 100%;
}

/* 未加入结算的数据 */
.bill_custom_class .el-table .tr_new_row {
  color: #409eff;
}
/* 数据不匹配 */
.bill_custom_class .el-table .tr_wrong_row {
  color: #f56c6c;
}
.bill_custom_class .el-table .tr_delete_row {
  color: var(--el-color-info-light-5);
}
</style> -->